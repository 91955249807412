* {
  box-sizing: border-box;
  margin: 0
}

body {
  color: #334155;
  font-family: system-ui, -apple-system, sans-serif;
  line-height: 1.5;
  background-color: #f8fafc;
  transition: background-color 0.4s ease, color 0.4s ease;
}

.dark body {
  color: #e2e8f0;
  background-color: #0f172a;
}

a {
  color: inherit;
  text-decoration: none;
}

#app .container {
  margin: 0 auto;
  max-width: 1080px;
  padding: 0 24px;
  width: 100%
}

/* 现代化的顶部导航栏 */
#header {
  background-color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid rgba(0,0,0,0.05);
  padding: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 50;
  backdrop-filter: blur(12px);
  transition: background-color 0.4s ease, border-color 0.4s ease;
}

.dark #header {
  background-color: rgba(15, 23, 42, 0.8);
  border-bottom-color: rgba(255,255,255,0.05);
}

#header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .logo {
  font-size: 1.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #fbbf24 0%, #f97316 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#header .navi {
  display: flex;
  gap: 1.5rem;
}

#header .navi a {
  color: #64748b;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  transition: all 0.2s;
}

#header .navi a:hover {
  color: #f59e0b;
  background-color: #fffbeb;
}

.dark #header .navi a {
  color: #94a3b8;
}

.dark #header .navi a:hover {
  color: #fbbf24;
  background-color: rgba(251, 191, 36, 0.1);
}

/* 修改主题切换按钮样式 */
.theme-toggle {
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
  color: #4b5563;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark .theme-toggle {
  color: #e5e7eb;
}

.theme-toggle:hover {
  background-color: #f3f4f6;
  color: #1a1a1a;
}

.dark .theme-toggle:hover {
  background-color: #374151;
  color: #ffffff;
}

.theme-toggle svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* 修改图标显示逻辑 */
.theme-toggle .sun,
.theme-toggle .moon {
  display: none;
}

html:not(.dark) .theme-toggle .moon {
  display: block;
}

html.dark .theme-toggle .sun {
  display: block;
}

/* 状态卡片容器 */
#uptime {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
}

/* 现代化��状态卡片 */
.site {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 1.25rem;
  padding: 1.5rem;
  box-shadow: 0 1px 2px rgba(0,0,0,0.04), 
              0 2px 4px rgba(0,0,0,0.03);
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1),
              box-shadow 0.3s ease;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
}

.dark .site {
  background-color: rgba(30, 41, 59, 0.9);
  border-color: rgba(255,255,255,0.05);
}

.site:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.08),
              0 2px 8px rgba(0,0,0,0.04);
}

.dark .site:hover {
  box-shadow: 0 8px 24px rgba(0,0,0,0.3);
}

.site .loading {
  width: 2.5rem;
  height: 2.5rem;
  margin: 2rem auto;
  border: 3px solid #f3f4f6;
  border-top-color: #f59e0b;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.dark .site .loading {
  border-color: #374151;
  border-top-color: #fbbf24;
}

.site .meta {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.site .meta .name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  width: 100%;
}

.dark .site .meta .name {
  color: #e5e7eb;
}

.site .meta .link {
  margin-left: 0;
  color: #64748b;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.site .meta .link:hover {
  color: #f59e0b;
}

.dark .site .meta .link {
  color: #94a3b8;
}

.dark .site .meta .link:hover {
  color: #fbbf24;
}

.site .meta .status {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  margin-left: 0;
  padding: 0.5rem 1.25rem;
  border-radius: 9999px;
  font-weight: 600;
  font-size: 0.875rem;
}

.site .meta .status.ok {
  color: #22c55e;
  background-color: rgba(34, 197, 94, 0.1);
}

.dark .site .meta .status.ok {
  color: #22c55e;
  background-color: rgba(34, 197, 94, 0.15);
}

.site .meta .status.fail {
  color: #ef4444;
  background-color: rgba(239, 68, 68, 0.1);
}

.dark .site .meta .status.fail {
  color: #ef4444;
  background-color: rgba(239, 68, 68, 0.15);
}

.site .meta .status.unknow {
  color: #6b7280;
  background-color: #f3f4f6;
}

.dark .site .meta .status.unknow {
  color: #9ca3af;
  background-color: rgba(107, 114, 128, 0.2);
}

/* 现代化的时间线 */
.site .timeline {
  display: flex;
  gap: 3px;
  margin: 1.5rem 0;
  background-color: #f1f5f9;
  padding: 6px;
  border-radius: 1rem;
}

.dark .site .timeline {
  background-color: #1e293b;
}

.site .timeline i {
  flex: 1;
  height: 2rem;
  border-radius: 0.625rem;
  transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
  cursor: pointer;
}

.site .timeline i.ok {
  background-color: #22c55e;
}

.site .timeline i.down {
  background-color: #ef4444;
}

.site .timeline i.none {
  background-color: #e2e8f0;
}

.dark .site .timeline i.none {
  background-color: #475569;
}

.site .timeline i:hover {
  transform: scaleY(1.15);
}

/* 摘要信息样式 */
.site .summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #f3f4f6;
  
  .stats-group {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    flex: 1;
    margin: 0 1.5rem;
    
    .time-range {
      color: #6b7280;
      font-size: 0.875rem;
    }
  }
  
  .average-uptime {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.875rem;
    background: linear-gradient(135deg, rgba(34, 197, 94, 0.1) 0%, rgba(34, 197, 94, 0.15) 100%);
    border: 1px solid rgba(34, 197, 94, 0.2);
    border-radius: 0.75rem;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(34, 197, 94, 0.1);
      border-color: rgba(34, 197, 94, 0.3);
    }
    
    .label {
      color: #15803d;
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.025em;
    }
    
    .value {
      color: #16a34a;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.dark .site .summary {
  border-top-color: #374151;
  color: #9ca3af;
  
  .stats-group {
    .time-range {
      color: #9ca3af;
    }
  }
  
  .average-uptime {
    background: linear-gradient(135deg, rgba(34, 197, 94, 0.15) 0%, rgba(34, 197, 94, 0.2) 100%);
    border-color: rgba(34, 197, 94, 0.25);
    
    &:hover {
      box-shadow: 0 4px 12px rgba(34, 197, 94, 0.15);
      border-color: rgba(34, 197, 94, 0.35);
    }
    
    .label {
      color: #4ade80;
    }
    
    .value {
      color: #22c55e;
    }
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .site .summary {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    
    .stats-group {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      margin: 0;
      width: 100%;
      
      .average-uptime {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

/* 修改提示框样式 */
.site .timeline i::after {
  content: attr(data-tip);
  position: absolute;
  left: 50%;
  bottom: calc(100% + 8px);
  transform: translateX(-50%);
  padding: 0.75rem 1rem;
  background-color: rgba(15, 23, 42, 0.85);
  color: #ffffff;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.dark .site .timeline i::after {
  background-color: rgba(30, 41, 59, 0.9);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.site .timeline i:hover::after {
  opacity: 1;
  visibility: visible;
}

/* 完全禁用 React-Tooltip */
.__react_component_tooltip {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

/* 页脚 */
#footer {
  text-align: center;
  padding: 3rem 0;
  color: #64748b;
  font-size: 0.875rem;
}

.dark #footer {
  color: #94a3b8;
}

#footer a {
  color: #f59e0b;
  font-weight: 600;
  transition: color 0.2s;
}

#footer a:hover {
  color: #fbbf24;
}

.dark #footer a {
  color: #fbbf24;
}

.dark #footer a:hover {
  color: #fcd34d;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  #header {
      padding: 0.75rem 0;
  }
  
  #header .navi {
      gap: 0.75rem;
  }
  
  .site {
      padding: 1.25rem;
  }
  
  .site .meta {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
  }
  
  .site .meta .status {
      position: static;
      margin-top: 0.5rem;
      display: inline-block;
  }
  
  .theme-toggle {
      padding: 0.375rem;
  }
  
  .theme-toggle svg {
      width: 1rem;
      height: 1rem;
  }
}

/* 汉堡菜单按钮 */
.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  margin-right: 0.5rem;
  
  span {
    display: block;
    width: 24px;
    height: 2px;
    background-color: #64748b;
    margin: 5px 0;
    transition: 0.3s;
  }
}

.dark .hamburger span {
  background-color: #94a3b8;
}

/* 移动端菜单 */
.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  padding: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  
  &.active {
    opacity: 1;
    visibility: visible;
  }
  
  a {
    display: block;
    padding: 0.75rem 1rem;
    color: #64748b;
    font-weight: 500;
    border-radius: 0.5rem;
    
    &:hover {
      color: #f59e0b;
      background-color: #fffbeb;
    }
  }
}

.dark .mobile-menu {
  background-color: rgba(15, 23, 42, 0.95);
  
  a {
    color: #94a3b8;
    
    &:hover {
      color: #fbbf24;
      background-color: rgba(251, 191, 36, 0.1);
    }
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }
  
  .hamburger {
    display: block;
  }
  
  .mobile-menu {
    display: block;
  }
  
  /* 汉堡菜单动画 */
  .hamburger.active {
    span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
    
    span:nth-child(2) {
      opacity: 0;
    }
    
    span:nth-child(3) {
      transform: rotate(-45deg) translate(7px, -7px);
    }
  }
}

/* 保持桌面端样式不变 */
@media (min-width: 769px) {
  .desktop-nav {
    display: flex;
    gap: 1.5rem;
  }
}

/* 语言切换器样式 */
.language-switcher {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 1000;
  
  .language-toggle-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.625rem 0.875rem;
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid #e2e8f0;
    border-radius: 0.75rem;
    color: #475569;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    backdrop-filter: blur(10px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    
    &:hover {
      border-color: #cbd5e1;
      background: #ffffff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      transform: translateY(-1px);
    }
    
    .fi {
      font-size: 1.125em;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
    }
    
    .language-name {
      margin: 0 0.25rem;
    }
    
    .arrow-icon {
      transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0.6;
      
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  
  .language-dropdown {
    position: absolute;
    bottom: calc(100% + 0.5rem);
    right: 0;
    background: rgba(255, 255, 255, 0.98);
    border: 1px solid #e2e8f0;
    border-radius: 0.75rem;
    padding: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.08), 
                0 2px 4px -1px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(10px);
    min-width: 160px;
    transform-origin: bottom right;
    animation: dropdownShow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    
    .language-option {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      width: 100%;
      padding: 0.625rem 0.75rem;
      border: none;
      background: none;
      color: #475569;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      border-radius: 0.5rem;
      transition: all 0.15s ease;
      
      &:hover {
        background-color: #f8fafc;
        color: #0f172a;
      }
      
      &.active {
        background-color: #f1f5f9;
        color: #0f172a;
        font-weight: 600;
      }
      
      .fi {
        font-size: 1.125em;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
      }
    }
  }
}

/* 暗色主题适配 */
.dark {
  .language-switcher {
    .language-toggle-button {
      background: rgba(15, 23, 42, 0.75);
      border-color: rgba(51, 65, 85, 0.5);
      color: #94a3b8;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      
      &:hover {
        background: rgba(30, 41, 59, 0.85);
        border-color: rgba(71, 85, 105, 0.6);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      }
      
      .fi {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
      }
    }
    
    .language-dropdown {
      background: rgba(15, 23, 42, 0.95);
      border-color: rgba(51, 65, 85, 0.5);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 
                  0 2px 4px -1px rgba(0, 0, 0, 0.1);
      
      .language-option {
        color: #94a3b8;
        
        &:hover {
          background-color: rgba(51, 65, 85, 0.3);
          color: #e2e8f0;
        }
        
        &.active {
          background-color: rgba(51, 65, 85, 0.5);
          color: #f1f5f9;
        }
        
        .fi {
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .language-switcher {
    bottom: 1rem;
    right: 1rem;
  }
  
  .language-toggle-button {
    padding: 0.5rem 0.75rem;
  }
  
  .dark .language-dropdown {
    background-color: rgba(15, 23, 42, 0.98);
  }
}

/* 网站品牌区域样式 */
#header .brand {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

#header .site-logo {
  object-fit: contain;
  border-radius: 6px;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
}

/* 调整原有logo样式 */
#header .logo {
  font-size: 1.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #fbbf24 0%, #f97316 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* 移动端适配 */
@media (max-width: 768px) {
  #header .brand {
    gap: 0.5rem;
  }
  
  #header .site-logo {
    width: 24px !important;
    height: 24px !important;
  }
}

/* 搜索栏样式 */
.search-bar {
  position: relative;
  margin: 2rem auto 1.5rem;
  max-width: 480px;
  width: 100%;
  
  input {
    width: 100%;
    padding: 0.875rem 1.25rem;
    padding-right: 3rem;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #e2e8f0;
    border-radius: 1rem;
    font-size: 0.9375rem;
    color: #1e293b;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    backdrop-filter: blur(10px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    
    &:hover {
      border-color: #cbd5e1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
    
    &:focus {
      outline: none;
      border-color: #94a3b8;
      box-shadow: 0 0 0 4px rgba(148, 163, 184, 0.1);
      background-color: #ffffff;
    }
    
    &::placeholder {
      color: #94a3b8;
      transition: color 0.2s ease;
    }
  }
  
  .clear-button {
    position: absolute;
    right: 1.125rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.375rem;
    color: #94a3b8;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      color: #64748b;
      background-color: #f1f5f9;
    }
    
    svg {
      width: 16px;
      height: 16px;
      transition: transform 0.2s ease;
    }
    
    &:hover svg {
      transform: scale(1.1);
    }
  }
}

/* 暗色主题适配 */
.dark .search-bar {
  input {
    background-color: rgba(15, 23, 42, 0.75);
    border-color: rgba(51, 65, 85, 0.5);
    color: #f1f5f9;
    
    &:hover {
      border-color: rgba(71, 85, 105, 0.6);
      background-color: rgba(15, 23, 42, 0.85);
    }
    
    &:focus {
      border-color: rgba(148, 163, 184, 0.5);
      box-shadow: 0 0 0 4px rgba(148, 163, 184, 0.1);
      background-color: rgba(15, 23, 42, 0.95);
    }
    
    &::placeholder {
      color: #64748b;
    }
  }
  
  .clear-button {
    color: #64748b;
    
    &:hover {
      color: #94a3b8;
      background-color: rgba(148, 163, 184, 0.1);
    }
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .search-bar {
    margin: 1.25rem auto 1rem;
    
    input {
      padding: 0.75rem 1rem;
      padding-right: 2.75rem;
      font-size: 0.875rem;
      border-radius: 0.875rem;
    }
    
    .clear-button {
      right: 1rem;
      padding: 0.3125rem;
    }
  }
}

/* 搜索高亮样式 */
.highlight {
  position: relative;
  background: linear-gradient(120deg, rgba(245, 158, 11, 0.15) 0%, rgba(245, 158, 11, 0.25) 100%);
  border-radius: 0.2rem;
  padding: 0.05rem 0.15rem;
  margin: 0 -0.05rem;
  color: #92400e;
  font-weight: 500;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  display: inline;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, #f59e0b, #f97316);
    border-radius: 1px;
    opacity: 0;
    transform: scaleX(0.8);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  &:hover {
    background: linear-gradient(120deg, rgba(245, 158, 11, 0.2) 0%, rgba(245, 158, 11, 0.3) 100%);
    color: #78350f;
    
    &::after {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

/* 暗色主题适配 */
.dark .highlight {
  background: linear-gradient(120deg, rgba(251, 191, 36, 0.15) 0%, rgba(251, 191, 36, 0.2) 100%);
  color: #fbbf24;
  
  &::after {
    background: linear-gradient(90deg, #fbbf24, #f59e0b);
  }
  
  &:hover {
    background: linear-gradient(120deg, rgba(251, 191, 36, 0.2) 0%, rgba(251, 191, 36, 0.25) 100%);
    color: #fcd34d;
  }
}

/* 多行文本高亮适配 */
.link .highlight {
  word-break: break-all;
  white-space: normal;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .highlight {
    padding: 0.025rem 0.1rem;
    margin: 0 -0.025rem;
    
    &::after {
      height: 1px;
    }
  }
}


/* 无搜索结果样式 */
.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  backdrop-filter: blur(8px);
  border: 1px solid #e2e8f0;
  margin: 2rem 0;
  
  svg {
    color: #94a3b8;
    margin-bottom: 1.5rem;
    animation: pulse 2s infinite;
  }
  
  .no-results-text {
    .primary-text {
      color: #475569;
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    
    .secondary-text {
      color: #64748b;
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }
    
    .search-term {
      display: inline-block;
      padding: 0.375rem 0.75rem;
      background: #f1f5f9;
      border-radius: 0.5rem;
      color: #475569;
      font-size: 0.875rem;
      font-family: monospace;
    }
  }
}

.dark .no-results {
  background: rgba(15, 23, 42, 0.5);
  border-color: rgba(51, 65, 85, 0.5);
  
  svg {
    color: #64748b;
  }
  
  .no-results-text {
    .primary-text {
      color: #e2e8f0;
    }
    
    .secondary-text {
      color: #94a3b8;
    }
    
    .search-term {
      background: rgba(51, 65, 85, 0.5);
      color: #e2e8f0;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .no-results {
    padding: 3rem 1.5rem;
    margin: 1rem 0;
    
    svg {
      width: 40px;
      height: 40px;
      margin-bottom: 1rem;
    }
    
    .no-results-text {
      .primary-text {
        font-size: 1rem;
      }
    }
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  /* 头部导航适配 */
  #header {
    padding: 0.75rem 0;
  }
  
  #header .container {
    padding: 0 1rem;
  }
  
  #header .logo {
    font-size: 1.25rem;
  }
  
  /* 卡片适配 */
  .site {
    padding: 1.25rem;
    border-radius: 1rem;
  }
  
  .site .meta {
    margin-bottom: 1.25rem;
  }
  
  .site .meta .name {
    font-size: 1.125rem;
  }
  
  .site .meta .status {
    position: static;
    margin-top: 0.5rem;
    padding: 0.375rem 1rem;
    font-size: 0.8125rem;
    display: inline-block;
  }
  
  /* 时间线适 */
  .site .timeline {
    margin: 1.25rem 0;
    padding: 4px;
    border-radius: 0.75rem;
  }
  
  .site .timeline i {
    height: 1.75rem;
    border-radius: 0.5rem;
  }
  
  /* 摘要信息适配 */
  .site .summary {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    font-size: 0.8125rem;
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    
    > span:first-child {
      color: #94a3b8;
      font-size: 0.75rem;
    }
    
    > span:last-child {
      align-self: flex-end;
      color: #94a3b8;
      font-size: 0.75rem;
    }
    
    .stats-group {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      margin: 0;
      width: 100%;
      
      .average-uptime {
        width: 100%;
        justify-content: space-between;
        padding: 0.5rem 0.75rem;
        
        .label {
          font-size: 0.75rem;
        }
        
        .value {
          font-size: 0.875rem;
        }
      }
      
      .time-range {
        font-size: 0.8125rem;
        line-height: 1.4;
      }
    }
  }
  
  /* 搜索框适配 */
  .search-bar {
    margin: 1rem 0;
    
    input {
      padding: 0.75rem 1rem;
      padding-right: 2.5rem;
      font-size: 0.875rem;
      border-radius: 0.75rem;
    }
    
    .clear-button {
      right: 0.75rem;
      padding: 0.375rem;
      
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  
  /* 无结果提示适配 */
  .no-results {
    padding: 2.5rem 1.25rem;
    margin: 1rem 0;
    border-radius: 0.75rem;
    
    svg {
      width: 36px;
      height: 36px;
      margin-bottom: 1rem;
    }
    
    .no-results-text {
      .primary-text {
        font-size: 1rem;
      }
      
      .secondary-text {
        font-size: 0.8125rem;
      }
      
      .search-term {
        padding: 0.25rem 0.5rem;
        font-size: 0.8125rem;
      }
    }
  }
  
  /* 容器适配 */
  #app .container {
    padding: 0 1rem;
  }
  
  #uptime {
    margin-top: 1rem;
    gap: 0.75rem;
  }
}

/* 小屏幕配 */
@media (max-width: 360px) {
  .site {
    padding: 1rem;
  }
  
  .site .meta .name {
    font-size: 1rem;
  }
  
  .site .meta .status {
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
  }
  
  .site .timeline i {
    height: 1.5rem;
  }
}

/* 公告样式 */
.notice-wrapper {
  padding: 1rem 0;
  backdrop-filter: blur(8px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  animation: noticeFadeIn 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  transform: translateY(0);
  
  &.notice-closing {
    animation: noticeFadeOut 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    
    .notice {
      animation: noticeSlideOut 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }
  
  .notice {
    max-width: 800px;
    margin: 0 auto;
    border-radius: 1rem;
    padding: 0.75rem;
    border: 1px solid;
    transition: all 0.3s ease;
    
    .notice-content {
      font-size: 0.9375rem;
      line-height: 1.5;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.25rem 0.75rem;
      
      .notice-icon {
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
        animation: noticeIconPulse 2s infinite;
      }
      
      .notice-text {
        flex: 1;
      }
      
      .notice-actions {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-left: 0.5rem;
        
        .notice-action {
          padding: 0.375rem;
          border: none;
          background: none;
          color: currentColor;
          opacity: 0.6;
          cursor: pointer;
          border-radius: 0.375rem;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          
          &:hover {
            opacity: 1;
            background: rgba(0, 0, 0, 0.05);
            transform: scale(1.1);
          }
          
          &:active {
            transform: scale(0.95);
          }
        }
      }
    }
    
    &.notice-warning {
      .notice-icon {
        animation: noticeWarningPulse 2s infinite;
      }
    }
    
    &.notice-error {
      .notice-icon {
        animation: noticeErrorShake 0.5s cubic-bezier(.36,.07,.19,.97) both;
      }
    }
    
    &.notice-success {
      .notice-content {
        animation: noticeSuccessBounce 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
    
    &.notice-priority {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      
      .notice-content {
        padding: 0.5rem 1rem;
        font-weight: 500;
      }
    }
    
    .notice-actions {
      .notice-action {
        position: relative;
        
        .notice-tooltip {
          position: absolute;
          top: calc(100% + 8px);
          left: 50%;
          transform: translateX(-50%);
          padding: 0.5rem 0.75rem;
          background: rgba(0, 0, 0, 0.8);
          color: white;
          font-size: 0.75rem;
          border-radius: 0.375rem;
          white-space: nowrap;
          pointer-events: none;
          transition: all 0.2s ease;
          animation: tooltipFadeIn 0.2s ease;
          
          &::after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 4px solid transparent;
            border-bottom-color: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }
}

/* 暗色模式预设样式 */
.dark .notice-wrapper {
  background: none;
  backdrop-filter: none;
  
  .notice {
    &.notice-info {
      background: rgba(59, 130, 246, 0.1) !important;
      border-color: rgba(59, 130, 246, 0.2) !important;
      color: #60a5fa !important;
    }
    
    &.notice-warning {
      background: rgba(245, 158, 11, 0.1) !important;
      border-color: rgba(245, 158, 11, 0.2) !important;
      color: #fbbf24 !important;
    }
    
    &.notice-success {
      background: rgba(34, 197, 94, 0.1) !important;
      border-color: rgba(34, 197, 94, 0.2) !important;
      color: #4ade80 !important;
    }
    
    &.notice-error {
      background: rgba(239, 68, 68, 0.1) !important;
      border-color: rgba(239, 68, 68, 0.2) !important;
      color: #f87171 !important;
    }
  }
}

/* 新增动画效果 */
@keyframes noticeWarningPulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

@keyframes noticeErrorShake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}

@keyframes noticeSuccessBounce {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes noticeFadeIn {
  from {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes noticeIconPulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .notice-wrapper {
    padding: 0.75rem 1rem;
    
    .notice {
      padding: 0.625rem;
      border-radius: 0.75rem;
      
      .notice-content {
        font-size: 0.875rem;
        gap: 0.625rem;
        padding: 0.125rem 0.5rem;
        
        .notice-icon {
          width: 1.125rem;
          height: 1.125rem;
        }
        
        .notice-actions {
          gap: 0.375rem;
          margin-left: 0.375rem;
          
          .notice-action {
            padding: 0.25rem;
          }
        }
      }
    }
  }
}

@keyframes tooltipFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -4px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* 添加新的关闭动画关键帧 */
@keyframes noticeFadeOut {
  from {
    opacity: 1;
    max-height: 200px;
    margin-top: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 0;
    max-height: 0;
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}

@keyframes noticeSlideOut {
  from {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  to {
    transform: translateY(-1rem) scale(0.95);
    opacity: 0;
  }
}

/* 监控分组样式 */
.monitor-group {
  margin-bottom: 2rem;
  position: relative;
  
  &.no-group-style {
    margin-bottom: 0;
    
    .site {
      margin-bottom: 1rem;
    }
  }
  
  .group-header {
    position: sticky;
    top: 72px; // 头部导航栏高度 + 一些间距
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.75rem 1.25rem;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 1rem;
    backdrop-filter: blur(12px);
    border: 1px solid rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    
    // 滚动时的效果
    &.sticky {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.08);
      background: rgba(255, 255, 255, 0.95);
    }
    
    .group-title-wrapper {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    
    .group-collapse-btn {
      padding: 0.375rem;
      border: none;
      background: none;
      color: #64748b;
      cursor: pointer;
      border-radius: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      
      svg {
        transition: transform 0.2s ease;
      }
      
      &:hover {
        background: rgba(0, 0, 0, 0.05);
        color: #475569;
      }
      
      &.collapsed svg {
        transform: rotate(-90deg);
      }
    }
    
    .group-title {
      font-size: 1.125rem;
      font-weight: 600;
      color: #1e293b;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      
      // 添加分组图标
      &::before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background: currentColor;
        border-radius: 50%;
        opacity: 0.5;
      }
      
      .group-count {
        font-size: 0.875rem;
        color: #64748b;
        margin-left: 0.5rem;
        font-weight: normal;
      }
    }
    
    .group-stats {
      display: flex;
      align-items: center;
      gap: 1rem;
      
      .group-uptime {
        font-size: 0.875rem;
        color: #64748b;
        padding: 0.5rem 0.875rem;
        background: linear-gradient(135deg, rgba(34, 197, 94, 0.1) 0%, rgba(34, 197, 94, 0.15) 100%);
        border-radius: 0.75rem;
        border: 1px solid rgba(34, 197, 94, 0.2);
        transition: all 0.2s ease;
        
        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(34, 197, 94, 0.1);
          border-color: rgba(34, 197, 94, 0.3);
        }
      }
    }
  }
  
  .site {
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    // 添加站点悬浮效果
    &:hover {
      transform: translateX(4px);
    }
  }
  
  .group-content {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    max-height: 9999px;
    transform-origin: top;
    transform: scaleY(1);
    
    &.collapsed {
      opacity: 0;
      max-height: 0;
      transform: scaleY(0);
      margin: 0;
      overflow: hidden;
    }
  }
}

/* 暗色主题适配 */
.dark .monitor-group {
  .group-header {
    background: rgba(15, 23, 42, 0.75);
    border-color: rgba(255, 255, 255, 0.05);
    
    &.sticky {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      border-color: rgba(255, 255, 255, 0.08);
      background: rgba(15, 23, 42, 0.95);
    }
    
    .group-title {
      color: #e2e8f0;
    }
    
    .group-stats {
      .group-uptime {
        color: #94a3b8;
        background: linear-gradient(135deg, rgba(34, 197, 94, 0.15) 0%, rgba(34, 197, 94, 0.2) 100%);
        border-color: rgba(34, 197, 94, 0.25);
        
        &:hover {
          box-shadow: 0 4px 12px rgba(34, 197, 94, 0.15);
          border-color: rgba(34, 197, 94, 0.35);
        }
      }
    }
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .monitor-group {
    margin-bottom: 1.5rem;
    
    .group-header {
      top: 64px; // 调整移动端sticky位置
      padding: 0.625rem 1rem;
      margin-bottom: 0.75rem;
      
      .group-title {
        font-size: 1rem;
        
        &::before {
          width: 0.375rem;
          height: 0.375rem;
        }
      }
      
      .group-stats {
        gap: 0.75rem;
        
        .group-uptime {
          font-size: 0.8125rem;
          padding: 0.375rem 0.625rem;
        }
      }
    }
    
    .site:hover {
      transform: none; // 移动端禁用悬浮效果
    }
  }
}

/* 添加滚动时的渐变效果 */
@media (min-width: 769px) {
  .monitor-group .group-header {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1rem;
      height: 1rem;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), transparent);
      opacity: 0;
      transition: opacity 0.3s ease;
      pointer-events: none;
    }
    
    &.sticky::after {
      opacity: 1;
    }
  }
  
  .dark .monitor-group .group-header {
    &::after {
      background: linear-gradient(to bottom, rgba(15, 23, 42, 0.1), transparent);
    }
  }
}

/* 筛选器样式 */
.filters-container {
  display: flex;
  gap: 0.75rem;
  margin: 2rem 0 1.5rem;
}

.filter-dropdown {
  position: relative;
  
  .filter-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.625rem 1rem;
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid #e2e8f0;
    border-radius: 0.75rem;
    color: #475569;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      border-color: #cbd5e1;
      background: #ffffff;
    }
    
    &.active {
      border-color: #94a3b8;
      background: #ffffff;
    }
    
    .arrow-icon {
      transition: transform 0.2s ease;
      
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  
  .filter-options {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    min-width: 160px;
    background: rgba(255, 255, 255, 0.98);
    border: 1px solid #e2e8f0;
    border-radius: 0.75rem;
    padding: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.08),
                0 2px 4px -1px rgba(0, 0, 0, 0.04);
    z-index: 30;
    animation: dropdownShow 0.2s ease;
    
    .filter-option {
      width: 100%;
      text-align: left;
      padding: 0.625rem 0.75rem;
      border: none;
      background: none;
      color: #475569;
      font-size: 0.875rem;
      cursor: pointer;
      border-radius: 0.5rem;
      transition: all 0.15s ease;
      
      &:hover {
        background: #f8fafc;
        color: #0f172a;
      }
      
      &.active {
        background: #f1f5f9;
        color: #0f172a;
        font-weight: 500;
      }
    }
  }
}

/* 暗色主题适配 */
.dark {
  .filter-button {
    background: rgba(15, 23, 42, 0.75);
    border-color: rgba(51, 65, 85, 0.5);
    color: #94a3b8;
    
    &:hover {
      background: rgba(15, 23, 42, 0.85);
      border-color: rgba(71, 85, 105, 0.6);
    }
    
    &.active {
      border-color: #64748b;
      background: rgba(15, 23, 42, 0.95);
    }
  }
  
  .filter-options {
    background: rgba(15, 23, 42, 0.98);
    border-color: rgba(51, 65, 85, 0.5);
    
    .filter-option {
      color: #94a3b8;
      
      &:hover {
        background: rgba(51, 65, 85, 0.3);
        color: #e2e8f0;
      }
      
      &.active {
        background: rgba(51, 65, 85, 0.5);
        color: #f1f5f9;
      }
    }
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .filters-container {
    margin: 1rem 0;
    gap: 0.5rem;
  }
  
  .filter-dropdown {
    .filter-button {
      padding: 0.5rem 0.75rem;
      font-size: 0.8125rem;
    }
    
    .filter-options {
      .filter-option {
        padding: 0.5rem 0.75rem;
        font-size: 0.8125rem;
      }
    }
  }
}

/* 搜索和筛选容器样式 */
.search-filter-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0 1.5rem;
  
  .search-bar {
    flex: 1;
    margin: 0;
  }
  
  .filters-container {
    display: flex;
    gap: 0.75rem;
    margin: 0;
  }
}

/* 移端适配 */
@media (max-width: 768px) {
  .search-filter-container {
    flex-direction: column;
    gap: 0.75rem;
    margin: 1rem 0;
    
    .search-bar {
      width: 100%;
    }
    
    .filters-container {
      width: 100%;
      justify-content: flex-start;
    }
  }
}

/* 筛选器样式补充 */
.clear-filters-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-radius: 0.75rem;
  color: #dc2626;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(239, 68, 68, 0.15);
    border-color: rgba(239, 68, 68, 0.25);
    transform: translateY(-1px);
  }
  
  svg {
    opacity: 0.7;
  }
}

.dark .clear-filters-btn {
  background: rgba(239, 68, 68, 0.15);
  border-color: rgba(239, 68, 68, 0.25);
  color: #f87171;
  
  &:hover {
    background: rgba(239, 68, 68, 0.2);
    border-color: rgba(239, 68, 68, 0.35);
  }
}

/* 骨架屏动画 */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* 骨架屏样式 */
.skeleton-wrapper {
  .skeleton-group {
    margin-bottom: 2rem;
    
    .skeleton-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 0.75rem 1.25rem;
      background: rgba(255, 255, 255, 0.85);
      border-radius: 1rem;
      backdrop-filter: blur(12px);
      border: 1px solid rgba(0, 0, 0, 0.05);
      
      .skeleton-title {
        width: 120px;
        height: 24px;
        background: #f1f5f9;
        border-radius: 0.375rem;
        animation: pulse 1.5s ease-in-out infinite;
      }
      
      .skeleton-stats {
        width: 100px;
        height: 20px;
        background: #f1f5f9;
        border-radius: 0.375rem;
        animation: pulse 1.5s ease-in-out infinite;
      }
    }
    
    .skeleton-site {
      margin-bottom: 1rem;
      padding: 1.5rem;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 1.25rem;
      border: 1px solid rgba(0, 0, 0, 0.05);
      
      .skeleton-meta {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1.5rem;
        
        .skeleton-name {
          width: 180px;
          height: 24px;
          background: #f1f5f9;
          border-radius: 0.375rem;
          animation: pulse 1.5s ease-in-out infinite;
        }
        
        .skeleton-url {
          flex: 1;
          height: 20px;
          background: #f1f5f9;
          border-radius: 0.375rem;
          animation: pulse 1.5s ease-in-out infinite;
        }
        
        .skeleton-status {
          width: 80px;
          height: 28px;
          background: #f1f5f9;
          border-radius: 9999px;
          animation: pulse 1.5s ease-in-out infinite;
        }
      }
      
      .skeleton-timeline {
        display: grid;
        grid-template-columns: repeat(30, 1fr);
        gap: 3px;
        margin: 1.5rem 0;
        padding: 6px;
        background: #f1f5f9;
        border-radius: 1rem;
        
        .skeleton-day {
          height: 2rem;
          background: #e2e8f0;
          border-radius: 0.375rem;
          animation: pulse 1.5s ease-in-out infinite;
          animation-delay: calc(0.1s * var(--index));
        }
      }
      
      .skeleton-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .skeleton-date {
          width: 100px;
          height: 20px;
          background: #f1f5f9;
          border-radius: 0.375rem;
          animation: pulse 1.5s ease-in-out infinite;
        }
        
        .skeleton-stats-group {
          flex: 1;
          margin: 0 1.5rem;
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          
          .skeleton-uptime {
            width: 120px;
            height: 24px;
            background: #f1f5f9;
            border-radius: 0.375rem;
            animation: pulse 1.5s ease-in-out infinite;
          }
          
          .skeleton-range {
            width: 200px;
            height: 20px;
            background: #f1f5f9;
            border-radius: 0.375rem;
            animation: pulse 1.5s ease-in-out infinite;
          }
        }
        
        .skeleton-today {
          width: 80px;
          height: 20px;
          background: #f1f5f9;
          border-radius: 0.375rem;
          animation: pulse 1.5s ease-in-out infinite;
        }
      }
    }
  }
}

/* 暗色主题适配 */
.dark .skeleton-wrapper {
  .skeleton-group {
    .skeleton-header {
      background: rgba(15, 23, 42, 0.75);
      border-color: rgba(255, 255, 255, 0.05);
      
      .skeleton-title,
      .skeleton-stats {
        background: #1e293b;
      }
    }
    
    .skeleton-site {
      background: rgba(30, 41, 59, 0.9);
      border-color: rgba(255, 255, 255, 0.05);
      
      .skeleton-meta {
        .skeleton-name,
        .skeleton-url,
        .skeleton-status {
          background: #1e293b;
        }
      }
      
      .skeleton-timeline {
        background: #1e293b;
        
        .skeleton-day {
          background: #334155;
        }
      }
      
      .skeleton-summary {
        .skeleton-date,
        .skeleton-stats-group .skeleton-uptime,
        .skeleton-stats-group .skeleton-range,
        .skeleton-today {
          background: #1e293b;
        }
      }
    }
  }
}

/* 站点统计样式 */
.site-statistics {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1.25rem;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.03);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.05);
  }
  
  .stat-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    transition: all 0.3s ease;
    min-width: 140px;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -0.75rem;
      transform: translateY(-50%);
      width: 1px;
      height: 60%;
      background: rgba(0, 0, 0, 0.05);
    }
    
    &:last-child::after {
      display: none;
    }
    
    .stat-value {
      font-size: 2rem;
      font-weight: 700;
      background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 1;
    }
    
    .stat-label {
      font-size: 0.875rem;
      font-weight: 500;
      color: #64748b;
      white-space: nowrap;
    }
    
    &.operational {
      .stat-value {
        background: linear-gradient(135deg, #15803d 0%, #22c55e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .stat-label {
        color: #15803d;
      }
    }
    
    &.down {
      .stat-value {
        background: linear-gradient(135deg, #b91c1c 0%, #ef4444 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .stat-label {
        color: #b91c1c;
      }
    }
    
    &.unknown {
      .stat-value {
        background: linear-gradient(135deg, #475569 0%, #64748b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .stat-label {
        color: #475569;
      }
    }
    
    &.uptime {
      .stat-value {
        background: linear-gradient(135deg, #1d4ed8 0%, #3b82f6 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .stat-label {
        color: #1d4ed8;
      }
    }
    
    &:hover {
      transform: translateY(-2px);
      
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: inherit;
        padding: 2px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
        -webkit-mask: 
          linear-gradient(#fff 0 0) content-box, 
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
      }
    }
  }
}

/* 暗色主题适配 */
.dark .site-statistics {
  background: rgba(15, 23, 42, 0.5);
  border-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  
  &:hover {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  }
  
  .stat-item {
    &::after {
      background: rgba(255, 255, 255, 0.05);
    }
    
    .stat-value {
      background: linear-gradient(135deg, #e2e8f0 0%, #f8fafc 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    .stat-label {
      color: #94a3b8;
    }
    
    &.operational {
      .stat-value {
        background: linear-gradient(135deg, #22c55e 0%, #4ade80 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .stat-label {
        color: #22c55e;
      }
    }
    
    &.down {
      .stat-value {
        background: linear-gradient(135deg, #ef4444 0%, #f87171 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .stat-label {
        color: #ef4444;
      }
    }
    
    &.unknown {
      .stat-value {
        background: linear-gradient(135deg, #94a3b8 0%, #cbd5e1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .stat-label {
        color: #94a3b8;
      }
    }
    
    &.uptime {
      .stat-value {
        background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .stat-label {
        color: #3b82f6;
      }
    }
    
    &:hover::before {
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
    }
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .site-statistics {
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
    
    .stat-item {
      flex: 1;
      min-width: calc(50% - 0.5rem);
      padding: 0.75rem 1rem;
      
      &::after {
        display: none;
      }
      
      .stat-value {
        font-size: 1.5rem;
      }
      
      .stat-label {
        font-size: 0.75rem;
      }
    }
  }
}

/* 骨架屏移动端适配 */
@media (max-width: 768px) {
  .skeleton-wrapper {
    .skeleton-group {
      margin-bottom: 1rem;
      
      .skeleton-header {
        padding: 0.75rem 1rem;
        margin-bottom: 0.75rem;
        
        .skeleton-title {
          width: 100px;
          height: 20px;
        }
        
        .skeleton-stats {
          width: 80px;
          height: 18px;
        }
      }
      
      .skeleton-site {
        padding: 1rem;
        margin-bottom: 0.75rem;
        
        .skeleton-meta {
          margin-bottom: 1rem;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.75rem;
          
          .skeleton-name {
            width: 140px;
            height: 20px;
          }
          
          .skeleton-url {
            width: 100%;
            height: 18px;
          }
          
          .skeleton-status {
            width: 70px;
            height: 24px;
          }
        }
        
        .skeleton-timeline {
          margin: 1rem 0;
          padding: 4px;
          gap: 2px;
          
          .skeleton-day {
            height: 1.5rem;
          }
        }
        
        .skeleton-summary {
          flex-direction: column;
          gap: 0.75rem;
          align-items: flex-start;
          
          .skeleton-date {
            width: 80px;
            height: 18px;
          }
          
          .skeleton-stats-group {
            width: 100%;
            margin: 0;
            
            .skeleton-uptime {
              width: 100px;
              height: 20px;
            }
            
            .skeleton-range {
              width: 160px;
              height: 18px;
            }
          }
          
          .skeleton-today {
            width: 60px;
            height: 18px;
            align-self: flex-end;
          }
        }
      }
    }
  }
}

/* 筛选器移动端优化 */
@media (max-width: 768px) {
  .filter-dropdown {
    flex: 1;
    min-width: 0; // 允许宽度收缩
    
    .filter-button {
      width: 100%;
      justify-content: space-between;
      
      .filter-text {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 0.5rem;
      }
    }
  }
  
  .filters-container {
    flex-wrap: wrap;
    gap: 0.5rem;
    
    .clear-filters-btn {
      width: 100%; // 清除按钮占满宽度
      justify-content: center;
    }
  }
}

/* 超小屏幕适配 */
@media (max-width: 360px) {
  .filter-dropdown {
    .filter-button {
      padding: 0.5rem 0.75rem;
      font-size: 0.75rem;
    }
    
    .filter-options {
      .filter-option {
        padding: 0.5rem 0.75rem;
        font-size: 0.75rem;
      }
    }
  }
}